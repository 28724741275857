export const ptBr = <const>{
  credentials: {
    email: 'E-mail',
    password: 'Senha',
  },
  login: {
    title: 'Acesse a dispensação',
    forgotPassword: 'Esqueci a senha',
    submit: 'Entrar',
    keepAwake: 'Mantenha-me conectado',
    errorMessage: 'Usuário ou senha incorretos',
    defaultErrorMessage:
      'Não foi possível acessar. Verifique com o suporte de TI da sua farmácia.',
    captchaError: 'Não foi possível acessar, refaça o desafio do captcha e tente novamente.',
    captchaLoading: 'Carregando informações...',
    captchaErrorRetry: 'Re-validando, informações...',
    captchaCriticalErrorTitle: 'Falha crítica no carregamento',
    captchaCriticalErrorInfo: 'Favor entrar em contato pelo e-mail',
  },
  register: {
    title: 'Cadastre sua farmácia',
    pharmacyField: 'Dados da farmácia',
    addressPharmacyField: 'Endereço da loja',
    emailHint:
      'Coloque o e-mail principal, ele será utilizado para acessar a plataforma',
    passwordField: 'Senha de acesso',
    passwordHint:
      'Sua senha deve conter 8 caracteres e ao menos 1 letra maiúscula',
    validations: {
      cepInvalid: 'CEP inválido',
      cnpjInvalid: 'CNPJ inválido',
      fieldRequired: 'Campo obrigatório',
      cepOnlyNumbers: 'Campo só deve ter números exemplo: 12345678',
      minLengthPassword: 'Senhas devem ter no mínimo 8 characteres',
      emailDontMatch: 'E-mail não coincide',
      passwordDontMatch: 'Senhas não coincidem',
    },
    form: {
      companyName: 'Nome da farmácia',
      email: 'Email da loja',
      confirmEmail: 'Confirme o e-mail',
      cnpj: 'CNPJ da Loja',
      phone: 'Telefone da loja',
      address: {
        street: 'Rua',
        number: 'Número',
        complement: 'Complemento',
        zipCode: 'CEP',
        neighborhood: 'Bairro',
        city: 'Cidade',
        state: 'Estado',
      },
      checkbox: {
        termsAndPolicies: 'Li e aceito os',
      },
      password: 'Senha',
      confirmPassword: 'Digite a senha novamente',
      submitButton: 'Continuar',
    },
    feedback: {
      success: {
        title: 'Boas-vindas ao iClinic Rx Farmácia!',
        subtitle:
          'Caso necessite de instruções, um passo a passo de como dispensar com iClinic Rx Farmácia foi enviado para o endereço cadastrado. Caso não receba o e-mail, verifique com a TI da sua farmácia se a mesma possui algum bloqueio.',
        subtitle2:
          'Sempre que precisar de ajuda, você poderá acessar o mesmo tutorial clicando no botão "Como dispensar", no menu superior.',
        submit: 'Fechar',
      },
      error:
        'Não foi possível realizar o cadastro, verifique os campos tente novamente!',
    },
  },
  loginPopover: {
    title: 'Para dispensar a receita',
    login: 'Já tenho conta',
    signUp: 'Crie sua conta',
  },
  recoverPassword: {
    title: 'Mudar a senha',
    submit: 'Enviar e-mail de recuperação',
    feedback: {
      success:
        'Nos próximos minutos você receberá um e-mail em: {{email}} com instruções para mudar sua senha.',
      error: 'Não foi possível recuperar sua senha no momento.',
    },
  },
  resetPassword: {
    title: 'Criar nova senha',
    rules: {
      minLenth: 'No mínimo, 8 caracteres',
      hasLetter: 'Uma letra',
      hasNumber: 'Um número',
    },
    fieldLabel: 'Nova senha',
    submit: 'Mudar minha senha',
    feedback: {
      error:
        'Não foi possível alterar a senha, tente novamente ou entre em contato com nosso suporte!',
      success: 'Senha alterada com sucesso!',
    },
  },
  changePassword: {
    title: 'Mudar senha',
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    confirmNewPassword: 'Confirme a nova senha',
    submit: 'Salvar',
    info: 'Sua conta está com uma senha temporária. Para ativá-la e começar a dispensar as receitas iClinic crie uma nova senha.',
    validations: {
      fieldRequired: 'Campo obrigatório',
      minLengthPassword: 'Senhas devem ter no mínimo 8 characteres',
      passwordRequirement: 'Senha deve ter no mínimo uma letra',
      passwordNumberRequirement: 'Senha deve ter no mínimo um número',
      passwordDontMatch: 'Senhas não coincidem',
    },
    feedback: {
      success: 'Sua senha foi alterada com sucesso.',
      error:
        'Não foi possível alterar sua senha, verifique se os campos foram preenchidos corretamente.',
    },
    buttons: {
      createNew: 'Criar nova senha',
      success: 'Ir para dispensação de receita',
    },
    fieldsConditions: {
      min: 'No mínimo 8 caracteres',
      minLetter: 'Pelo menos uma letra',
      minNumber: 'Pelo menos um número',
    },
  },
}
