import { styled } from '@mui/material'
import Alert from '@mui/material/Alert'
import MUILink from '@mui/material/Link'
import { useI18n } from 'i18n'

const EMAIL = 'suporte@iclinicrx.com.br'

const Title = styled('h3')(({ theme }) => ({
  fontSize: theme.iclinic.font.size.sm,
  margin: '0 0 0.4rem 0',
  padding: '0',
}))

const Link = styled(MUILink)({
  fontSize: 'inherit',
  fontWeight: '400',
  textDecoration: 'none',
  display: 'block',
})

export function CaptchaErrorDialog() {
  const { t } = useI18n()

  return (
    <Alert severity="error" data-testid="captcha-critical-error">
      <Title>{t('features.auth.login.captchaCriticalErrorTitle')}</Title>
      <span>{t('features.auth.login.captchaCriticalErrorInfo')}</span>
      <Link href={`mailto:${EMAIL}`}>{EMAIL}</Link>
    </Alert>
  )
}
