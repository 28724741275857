import { useState } from 'react'

import { CaptchaWidget } from 'features/Captcha/Widget'

import { Form } from './Form'

export function Login() {
  const [captchaToken, setCaptchaToken] = useState<string>()
  const [captchaCriticalError, setCaptchaCriticalError] = useState<string>()
  const [captchaError, setCaptchaError] = useState<string>()

  /**
   * All codes can be find here
   * https://developers.cloudflare.com/turnstile/troubleshooting/client-side-errors/error-codes/
   * * */
  const CRITICAL_ERROR_CODES = [
    '110200',
    '110430',
    '110500',
    '110510',
    '200010',
    '200100',
  ]

  return (
    <>
      <CaptchaWidget
        action="login"
        onVerify={(token) => setCaptchaToken(token)}
        onError={(errorKey) => {
          if (CRITICAL_ERROR_CODES.some((errors) => errors === errorKey)) {
            setCaptchaCriticalError(errorKey)
          }

          setCaptchaError(errorKey)
        }}
      />
      <Form
        captchaToken={captchaToken}
        captchaCriticalError={captchaCriticalError}
        captchaError={captchaError}
      />
    </>
  )
}
